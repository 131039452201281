import { Tooltip } from "@material-ui/core"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  DescriptionWrapper,
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/components/Paradise/useTableColumns/useTableColumns"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { colorsLegacy } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import InfoIcon from "src/ui/icons/info.svg"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { formatDate } from "src/utils/l10n"

const tableColumns: TableColumn<IOrganization>[] = [
  {
    value: "organization_id",
    label: "Organization id",
    disabled: true,
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (organization) => {
      return (
        <IdWrapper>
          <InternalLink
            to={Routes.ParadiseOrganization.location(organization.id)}
          >
            {organization.id}
          </InternalLink>
          {organization.deleted && (
            <Tooltip title="Deleted">
              <InfoIcon
                width={12}
                fill={colorsLegacy.systemEmergencyForeground}
              />
            </Tooltip>
          )}
        </IdWrapper>
      )
    },
  },
  {
    value: "name",
    label: "Name",
    disabled: true,
    columnWidth: "min-content",
    render: (organization) => {
      return <div>{organization.name}</div>
    },
  },
  {
    value: "owner_id",
    label: "Owner id",
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (organization) => {
      if (organization.owner_id) {
        return (
          <InternalLink
            to={Routes.ParadiseUser.location(organization.owner_id)}
          >
            {organization.owner_id}
          </InternalLink>
        )
      }

      return "-"
    },
  },
  {
    value: "created_at",
    label: "Created at",
    columnWidth: "auto",
    render: (organization) => {
      if (organization.created_at) {
        return (
          <div>
            {formatDate({ date: organization.created_at, clockType: "24" })}
          </div>
        )
      }

      return "-"
    },
  },
]

const LIMIT = 20

export function ParadiseOrganizations() {
  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "name",
        type: "string",
      },
      {
        key: "id",
        type: "string",
      },
    ],
  })

  const { navigate } = useRouter()

  const { offset, setOffset } = useUrlPager({ initialLimit: LIMIT })

  const fetchOrganizations = useFetchOrganizations({
    params: {
      limit: LIMIT,
      offset: offset,
      name: filter.name || undefined,
      id: filter.id || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    setColumnVisibility,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchOrganizations.data?.organizations,
    options: {
      localStorageKey: "minut.paradise.organitions.columns",
    },
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter(filterKey, value)
  }

  return (
    <ParadiseLayout>
      <Titlebar
        title="Organizations"
        description={
          <DescriptionWrapper>
            <MText variant="body">Total:</MText>
            <MText variant="body">
              {!fetchOrganizations.isLoading ? (
                fetchOrganizations.data?.paging.total_count
              ) : (
                <MSkeleton width="8ch" />
              )}
            </MText>
          </DescriptionWrapper>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <SearchFilter
            initialValue={filter.id ?? undefined}
            placeholder="Search by id"
            onChange={(input) => handleFilterChange("id", input)}
          />
          <SearchFilter
            initialValue={filter.name ?? undefined}
            placeholder="Search by name"
            onChange={(input) => handleFilterChange("name", input)}
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          options={interactiveColumns}
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          onChange={({ checked, option }) => {
            setColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchOrganizations.data?.organizations) {
            navigate(
              Routes.ParadiseOrganization.location(
                fetchOrganizations.data.organizations[index].id
              )
            )
          }
        }}
      />
      {fetchOrganizations.isError && (
        <InfoBox type="warning" title={fetchOrganizations.error.message}>
          {fetchOrganizations.error.response?.data}
        </InfoBox>
      )}
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchOrganizations.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}
